<template>
  <v-container id="login-view" class="fill-height" tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="accent"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">Login</div>
              </div>
            </template>

            <v-card-text class="text-center">
              <v-text-field
                v-model="username"
                class="mt-10"
                color="accent"
                placeholder="Username"
                prepend-icon="mdi-face"
                :rules="[rules.required]"
              />

              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                class="mb-8"
                color="accent"
                placeholder="Password"
                prepend-icon="mdi-lock-outline"
                :rules="[rules.required]"
                @click:append="showPassword = !showPassword"
                @keyup.enter="login"
              />

              <loading-btn
                color="accent"
                size="large"
                :text="true"
                :rounded="true"
                :loading="loading"
                loadingText="please wait, login is in progress..."
                @click="login"
              >
                Log in
              </loading-btn>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",

  data: () => ({
    username: "",
    password: "",
    loading: false,
    showPassword: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  methods: {
    login() {
      this.loading = true;
      if (this.username && this.password) {
        this.$store
          .dispatch("authUser/login", {
            url: "Authentications/Login",
            username: this.username,
            password: this.password,
          })
          .then(() => {
            this.$toast.success(
              "Logged in successfully.",
              "Success",
              {
                position: "topRight",
              },
              this.$router.push("/")
            );
            this.loading = false;
          })
          .catch(() => {
            this.$toast.error("Incorrect username or password.", "Error", {
              position: "topRight",
            });
            this.loading = false;
          });
      }
    },
  },
};
</script>
